<template>
  <main class="color-primary h-100 page_bg_grey blank-aside-js pb-15" :class = "(isOpenAside=='true')?'blank-aside':''">
    <p class="main-title weight-700">
      {{ $t('Banner management') }}
    </p>
    <div class="weight-700 d-flex filter-part">
      <div class="filter-item mb-5">
        <label class="" for>{{ $t('Advertiser') }}</label>
        <vue-select
          :options="filter.advertiser.options"
          class="input cus-select filter-dropdown"
          v-model="filter.advertiser.selected"
        ></vue-select>
      </div>
      <div class="filter-item mb-5">
        <label class="" for>{{ $t('Banner status') }}</label>
        <vue-select
          :options="filter.banner_status.options"
          class="input cus-select filter-dropdown"
          v-model="filter.banner_status.selected"
        ></vue-select>
      </div>
      <div class="filter-item mb-5">
        <label class="" for>{{ $t('Location') }}</label>
        <vue-select
          :options="filter.location.options"
          class="input cus-select filter-dropdown"
          v-model="filter.location.selected"
        ></vue-select>
      </div>
      <div class="filter-item mb-5">
        <label class="" for>{{ $t('Tags') }}</label>
        <vue-select
          :options="filter.tags.options"
          class="input cus-select filter-dropdown"
          v-model="filter.tags.selected"
        ></vue-select>
      </div>
      <div class="filter-item mb-5">
        <label class="" for>{{ $t('Banner Type') }}</label>
        <vue-select
          :options="filter.type.options"
          class="input cus-select filter-dropdown"
          v-model="filter.type.selected"
        ></vue-select>
      </div>
      <div class="date-selector">
        <div class="d-flex justify-content-between align-items-center mb-5">
          <label class="" for>{{ $t('Start Date') }}: </label>
          <div class="d-flex align-items-center p-relative">
            <Datepicker
              v-model="filter.start_date"
              :format="format"
              class="input cus-datepicker"
              :language="languages[currentLang]"
            ></Datepicker>
            <img src="/assets/img/icons/calendar-alt-light.svg" alt="" class="calendar-img" />
          </div>
        </div>
        <div class="d-flex justify-content-between align-items-center mb-5">
          <label class="" for>{{ $t('End Date') }}: </label>
          <div class="d-flex align-items-center p-relative">
            <Datepicker
              v-model="filter.end_date"
              :format="format"
              class="input cus-datepicker"
              :language="languages[currentLang]"
            ></Datepicker>
            <img src="/assets/img/icons/calendar-alt-light.svg" alt="" class="calendar-img" />
          </div>
        </div>
      </div>
    </div>

    <div class="table-content-part">
      <div class="d-flex justify-content-between">
        <div class="search-bar">
          <p class="search-label">{{ $t('Search') }}</p>
          <v-text-field
            v-model="search"
            single-line
            hide-details
            class="search-form"
          ></v-text-field>
        </div>
      </div>
      <v-data-table
        :headers="headers"
        :search="search"
        :items="tableData"
        class="cus-table editable-table main-table striped-table"
        item-key="id"
        :loading="loading"
        :custom-filter="filterOnlyByName"
      >
        <template v-slot:[`item.status`]="{ item }">
          <div class="ticket-item bg-green1" v-if="item.status == 1">
            {{ $t('Active') }}
          </div>
          <div class="ticket-item bg-3" v-else>
            {{ $t('Inactive') }}
          </div>
        </template>
        <template v-slot:[`item.locations`]="{ item }">
          <div v-if="item.locations.length" class="d-flex flex-wrap justify-content-center">
            <div class="ticket-location" 
              v-for="(loc, index) in item.locations" 
              :key="index"
            >
              {{ getLactionStringById(loc) }}
            </div>
          </div>
          <div v-else>
            N/A
          </div>
        </template>
        <template v-slot:[`item.tags`]="{ item }">
          <div v-if="item.tags.length" class="d-flex flex-wrap justify-content-center">
            <div class="ticket-location" v-for="(tag, index) in item.tags" :key="index">
              {{ getTagStringById(tag) }}
            </div>
          </div>
          <div v-else>
            N/A
          </div>
        </template>
        <template v-slot:[`item.presentation`]="{ }">
          <div class="show-btn">
            {{ $t('Show') }}
          </div>
        </template>
        <template v-slot:[`item.editing`]="{ item }">
          <img src="/assets/img/icons/edit.svg" alt="" class="edit-icon pointer"  @click="openEditModal(item.id)" />
        </template>
        <template v-slot:[`item.delete`]="{ item }">
          <img src="/assets/img/trash.svg" alt="" 
            class="edit-icon pointer"  
            @click="deleteBanner(item.id)" 
          />
        </template>
        <template v-slot:[`item.start_date`]="{ item }">
          {{ item.start_date | formatDate }}
        </template>
        <template v-slot:[`item.end_date`]="{ item }">
          {{ item.end_date | formatDate }}
        </template>
        <template v-slot:[`item.hours`]="{ item }">
          <p class="m-0">
            {{ item.start_date | formatTime }}
          </p>
          <p class="m-0">
            {{ item.end_date | formatTime }}
          </p>
        </template>
      </v-data-table>
    </div>

    <div class="action-part">
      <button class="primary-btn new-btn" @click="openCreateModal()">
        {{ $t('New') }}
      </button>
      <router-link :to="{name: 'SCAdsAdvertisers'}" tag="a" class="primary-btn cancel-btn color-white" exact>
        {{ $t('Advertisers') }}
      </router-link>
    </div>

    <NewEditBannerModal 
      :selectedBanner="selectedBanner" 
      :isEdit="isEdit"
      @finishUpdate="finishUpdate"
    ></NewEditBannerModal>

    <loading
      :active.sync="loading"
      :can-cancel="false"
      :is-full-page="true"
    ></loading>
  </main>
</template>

<script>
import 'bootstrap';
import '../../../common/plugins/bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import { mapState, mapActions } from 'vuex';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

import NewEditBannerModal from '../../../components/management/NewEditBannerModal.vue';

export default {
  mixins: [validationMixin],
  name: 'SCAds',
  components: {
    Loading,
    NewEditBannerModal
  },
  computed: {
    ...mapState({
      loading: (state) => state.report.loading,
      banners: (state) => state.category_products.banners,
      locations: (state) => state.category_products.locations,
      tags: (state) => state.category_products.tags,
    }),
  },
  data() {
    return {
      tableData: [],
      search: '',
      headers: [
        {
          text: this.$t('Name'),
          align: 'center',
          sortable: true,
          value: 'name',
        },
        {
          text: this.$t('There'),
          align: 'center',
          sortable: false,
          value: 'there',
        },
        {
          text: this.$t('Location'),
          align: 'center',
          sortable: true,
          value: 'locations',
        },
        {
          text: this.$t('Order'),
          align: 'center',
          sortable: true,
          value: 'order',
        },
        {
          text: this.$t('Tags'),
          align: 'center',
          sortable: true,
          value: 'tags',
        },
        {
          text: this.$t('Banner Type'),
          align: 'center',
          sortable: false,
          value: 'type',
        },
        {
          text: this.$t('Language'),
          align: 'center',
          sortable: true,
          value: 'language',
        },
        {
          text: this.$t('Length'),
          align: 'center',
          sortable: true,
          value: 'duration',
        },
        {
          text: this.$t('Status'),
          align: 'center',
          sortable: true,
          value: 'status',
        },
        {
          text: this.$t('A. Beginning'),
          align: 'center',
          sortable: true,
          value: 'start_date',
        },
        {
          text: this.$t('A. End'),
          align: 'center',
          sortable: true,
          value: 'end_date',
        },
        {
          text: this.$t('Days'),
          align: 'center',
          sortable: false,
          value: 'days',
        },
        {
          text: this.$t('Hours'),
          align: 'center',
          sortable: true,
          value: 'hours',
        },
        {
          text: this.$t('Presentation'),
          align: 'center',
          sortable: false,
          value: 'presentation',
        },
        {
          text: this.$t('Bounce'),
          align: 'center',
          sortable: true,
          value: 'num_bounce',
        },
        {
          text: this.$t('Editing'),
          align: 'center',
          sortable: false,
          value: 'editing',
        },
        {
          text: this.$t('Delete'),
          align: 'center',
          sortable: false,
          value: 'delete',
        }
      ],
      filter: {
        advertiser: {
          selected: { label: 'הכל', code: 0 },
          options: [{ label: 'הכל', code: 0 }],
        },
        banner_status: {
          selected: { label: 'הכל', code: 0 },
          options: [{ label: 'הכל', code: 0 }],
        },
        location: {
          selected: { label: 'הכל', code: 0 },
          options: [{ label: 'הכל', code: 0 }],
        },
        tags: {
          selected: { label: 'הכל', code: 0 },
          options: [{ label: 'הכל', code: 0 }],
        },
        type: {
          selected: { label: 'הכל', code: 0 },
          options: [{ label: 'הכל', code: 0 }],
        },
        start_date: '',
        end_date: '',
      },
      isEdit: true,
      format: 'd MMMM yyyy',
      selectedBanner: {}
    };
  },
  async created() {
    if (this.banners.length == 0) {
      await this.getBanners();
    }
    this.tableData = this.banners;

    if (this.tags.length == 0) {
      await this.getTagsByType({type: 3});
    }

    if (this.locations.length == 0) {
      await this.getLocations();
    }

    if (this.banners.length != 0) {
     
      this.banners.forEach(el => {
        this.filter.location.options.push({
          label: el.location,
          code: el.id
        })
      });
    }
  },
  methods: {
    ...mapActions("category_products", {
      getBanners: "getBanners",
      getLocations: "getLocations",
      getTagsByType: "getTagsByType",
    }),

     /* <!-- ------------create modal-------------- --> */
    openEditModal(id) {
      this.selectedBanner = this.banners.find(el => {
        return el.id == id;
      });
      this.isEdit = true;
      this.$modal.show("NewEditBannerModal");
    },
    openCreateModal() {
      this.selectedBanner = {};
      this.isEdit = false;
      this.$modal.show("NewEditBannerModal");
    },
    getLactionStringById (id) {
      if (this.locations.length != 0) {
        const locItem = this.locations.find(el => {
          return el.id == id;
        });
        return locItem.location;
      }
    },
    getTagStringById (id) {
      if (this.tags.length != 0) {
        const tagItem = this.tags.find(el => {
          return el.id == id;
        });
        return tagItem.name;
      }
    },
    async finishUpdate() {
      this.$modal.hide("NewEditBannerModal");
      await this.getBanners();
      this.tableData = this.banners;
    },
    filterOnlyByName (value, search, item) {
      return value != null &&
        search != null &&
        typeof value === 'string' &&
        item.name.toString().indexOf(search) !== -1
    },
    deleteBanner(id) {
      
    }
  },
  mounted() {
    this.filter.start_date = new Date();
  },
};
</script>

<style lang="scss">
.cus-select {
  ul {
    padding-left: 0;
  }
  .vs__selected {
    font-weight: 300;
  }
}
.inline-checkbox .v-input__control {
  height: 100%;
}

</style>
<style scoped lang="scss">
label {
  margin-bottom: 0;
}
.filter-part {
  margin: 20px 40px 0 40px;
  .filter-item {
    width: 100%;
    padding: 0 10px;
  }
  .input {
    height: 30px;
    padding: 0 10px;
    border-radius: 8px;
  }
  .calendar-img {
    width: 20px;
    position: absolute;
    right: 12px;
    top: 5px;
    left: unset;
  }
  .filter-dropdown {
    min-width: 170px;
    height: 40px;
    border: none;
    border-radius: 0;
  }
  .date-selector {
    min-width: 350px;
    padding: 0 10px;
    margin-top: 20px;
    .cus-datepicker {
      max-width: 200px;
    }
  }
}

.main-table {
  .ticket-item {
    box-shadow: 0px 3px 6px #00000029;
    color: #fff;
    border-radius: 7px;
    margin: 0 auto;
    text-align: center;
    padding: 5px 0;
  }
  .ticket-location {
    // box-shadow: 0px 3px 6px #00000029;
    background-color: grey;
    color: #fff;
    border-radius: 5px;
    margin: 2px;
    text-align: center;
    padding: 2px 4px;
  }
}

.bounce-btn {
  width: 24px;
  height: 24px;
  border: 2.5px solid #0d3856;
  border: 2px solid #0d3856;
  border-radius: 5px;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 14px;
  }
}

.action-part {
  margin: 20px 50px 0 50px;
  display: flex;
  .primary-btn {
    border-radius: 10px;
    width: 200px;
    height: 44px;
  }
  .new-btn {
    background-color: #E2B629;
  }
  .cancel-btn {
    background-color: #0D3856;
    margin: 0 20px;
  }
}
.rtl-type {
  .filter-part {
    .calendar-img {
      left: 12px;
      right: unset;
    }
  }
}

@media screen and (max-width: 1650px) {
  .filter-part {
    flex-wrap: wrap;
    .filter-item {
      max-width: 250px;
    }
  }
}
@media screen and (max-width: 480px) {
  .action-part
  .filter-part {
    margin: 20px 10px 0;
    .filter-item {
      max-width: 100%;
    }
  }
}
</style>